import { NEWS_API_ENDPOINT } from '~/config.js';
import axios from 'axios';

const http = axios.create({
    baseURL: NEWS_API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
    },
});

export const getRuNews = function (channels_ru, limit) {
    return http.get(`/channels/lenta?channel_id=${channels_ru}&limit=${limit}`).then(({ data }) => data);
};

export const getEnNews = function (channels_en, limit) {
    return http.get(`/channels/lenta?channel_id=${channels_en}&limit=${limit}`).then(({ data }) => data);
};
