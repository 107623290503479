<template>
    <div class="diagramOutline">
        <div ref="diagramContainer" class="diagramGrabber" v-bind:class="{ 'is-dragging': isDragging }" v-on:mousedown="startDrag" style="overflow: auto;">
            <div ref="diagram" class="diagramWrapper"></div>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import Vue from 'vue';
import Tooltip from './DiagramTooltip.vue';
import { formatTons } from '../../../helpers.js';

export default {
    name: 'Diagram',
    props: {
        trace: Object,
        connections: Array,
    },

    data() {
        return {
            treeData: null,
            links: null,
            isDragging: false,
        };
    },

    watch: {
        trace: {
            handler: 'loadData',
            immediate: false,
        },
    },

    mounted() {
        this.updateScrollGradient();
        this.$refs.diagramContainer.addEventListener('scroll', this.updateScrollGradient);
    },

    beforeDestroy() {
        this.$refs.diagramContainer.removeEventListener('scroll', this.updateScrollGradient);
    },

    methods: {
        loadData() {
            this.links = this.connections;
            this.treeData = this.trace;
            this.createDiagram();
        },

        mountTooltip(data) {
            const Constructor = Vue.extend(Tooltip);
            const instance = new Constructor({
                propsData: { data },
            }).$mount();
            return instance.$el.outerHTML;
        },

        createDiagram() {
            const data = this.treeData;

            const margin = { top: 80, right: 50, bottom: 80, left: 50 };
            const fixedNodeWidth = 220;
            const fixedNodeHeight = 60;
            const fixedNodeHeightMin = 60;

            const svgContainer = d3.select(this.$refs.diagramContainer)
                .style('overflow-x', 'auto')
                .style('overflow-y', 'auto');

            const svg = d3.select(this.$refs.diagram)
                .append('svg')
                .append('g');

            const defs = svg.append('defs');

            defs.append('marker')
                .attr('id', 'arrowhead')
                .attr('viewBox', '-10 -5 10 10')
                .attr('refX', 0)
                .attr('refY', 0)
                .attr('markerWidth', 6)
                .attr('markerHeight', 6)
                .attr('orient', 'auto')
                .append('path')
                .attr('d', 'M -10,-5 L 0,0 L -10,5')
                .attr('stroke', 'var(--diagram-line-color)')
                .attr('fill', 'var(--diagram-line-color)');

            const nodeTooltip = d3Tip()
                .attr('class', 'd3-tip node-tooltip')
                .offset([-10, 0])
                .html(d => this.mountTooltip({
                    'type': 'node',
                    'Address': d.data.address,
                    'TX Hash': d.data.transaction.hash,
                }));

            const linkTooltip = d3Tip()
                .attr('class', 'd3-tip link-tooltip')
                .offset([-10, 0])
                .html(d => this.mountTooltip({
                    type: 'link',
                    Value: `${formatTons(d.target.data.value)} TON`,
                    Operation: `${d.target.data.opCode} · ${d.target.data.label}`,
                }));

            svg.call(nodeTooltip);
            svg.call(linkTooltip);

            const root = d3.hierarchy(data);
            const descendantNodes = root.descendants();

            const treeLayout = d3.tree().nodeSize([fixedNodeHeight, fixedNodeWidth]);
            treeLayout(root);

            const updateNodesPosition = (node) => {
                if (node.children && node.children.length > 5) {
                    const subtree = d3.hierarchy(node.data);
                    const subtreeLayout = d3.tree().nodeSize([fixedNodeHeightMin, fixedNodeWidth]);
                    subtreeLayout(subtree);

                    subtree.each((d, i) => {
                        if (i === 0) return;

                        const correspondingNode = descendantNodes.find(n => n.data === d.data);
                        if (correspondingNode) {
                            correspondingNode.x = d.x;
                        }
                    });
                }

                if (node.children) {
                    node.children.forEach(updateNodesPosition);
                }
            };

            updateNodesPosition(root);

            const isBranched = node => node.children && node.children.length > 1;

            const getLinkGenerator = (d) => {
                const sourceX = d.source.x;
                const sourceY = d.source.y;
                const targetX = d.target.x;
                const targetY = d.target.y;

                const markerEnd = 45;
                const markerStart = 45;

                if (isBranched(d.source)) {
                    const children = d.source.children;
                    const index = children.indexOf(d.target);
                    const isFirst = index === 0;
                    const isLast = index === children.length - 1;
                    const isOddCount = children.length % 2 !== 0;
                    const isCenterPoint = isOddCount && index === Math.floor(children.length / 2);

                    if (isFirst) {
                        const vertShift = sourceX - targetX - 10;
                        return `M${sourceY},${sourceX}V${sourceX - vertShift}a10 10 0 0 1 10 -10H${targetY - markerEnd}`;
                    } if (isLast) {
                        const vertShift = targetX - sourceX - 10;
                        return `M${sourceY},${sourceX}V${sourceX + vertShift}a10 10 0 0 0 10 10H${targetY - markerEnd}`;
                    } if (isCenterPoint) {
                        return `M${sourceY},${sourceX}H${targetY - markerEnd}`;
                    }
                    const vertShift = targetX - sourceX;
                    return `M${sourceY},${sourceX + vertShift}H${targetY - markerEnd}`;
                }
                return `M${sourceY + markerStart},${sourceX}H${targetY - markerEnd}`;
            };

            // eslint-disable-next-line no-unused-vars
            const links = svg.selectAll('path.link')
                .data(root.links())
                .enter()
                .append('path')
                .attr('class', 'link')
                .attr('d', getLinkGenerator)
                .attr('fill', 'none')
                .attr('stroke', 'var(--diagram-line-color)')
                .attr('marker-end', 'url(#arrowhead)');

            const handleMouseOver = (event, d) => {
                const name = d.data.name;
                d3.selectAll('.line-circle')
                    .filter(function () {
                        return this.innerText === name;
                    })
                    .classed('highlight', true);
            };

            const handleMouseOut = (event, d) => {
                const name = d.data.name;
                d3.selectAll('.line-circle')
                    .filter(function () {
                        return this.innerText === name;
                    })
                    .classed('highlight', false);
            };

            const handleMouseClick = function (element, event, d) {
                const dataPack = d.data;
                this.$emit('node-clicked', dataPack);

                d3.selectAll('.line-circle-wrapper').classed('line-select', false);
                d3.select(element).select('.line-circle-wrapper').classed('line-select', true);
            };

            const nodeGroups = svg.selectAll('g.node-group')
                .data(descendantNodes)
                .enter().append('g')
                .attr('class', 'node-group')
                .attr('transform', d => `translate(${d.y - 35},${d.x - 15})`)
                .on('mouseover', function (event, d) {
                    nodeTooltip.show(d, this);

                    handleMouseOver(event, d);
                })
                .on('mouseout', function (event, d) {
                    setTimeout(() => {
                        if (!d3.select('.node-tooltip:hover').empty()) return;
                        nodeTooltip.hide(d, this);
                    }, 100);

                    handleMouseOut(event, d);
                })
                .on('click', (event, d) => {
                    handleMouseClick.call(this, event.currentTarget, event, d);
                });

            const nodes = nodeGroups.append('foreignObject')
                .attr('class', 'node')
                .attr('width', 80)
                .attr('height', 30);

            nodes.append('xhtml:div')
                .html(d => `
                    <div class="line-circle-wrapper">
                        <div class="line-circle">
                            ${d.data.name}
                        </div>
                    </div>
                `);

            const linkTextGroups = svg.selectAll('.link-text-group')
                .data(root.links())
                .enter().append('g')
                .attr('class', 'link-text-group')
                .attr('transform', (d) => {
                    const x = Math.min(d.source.y, d.target.y) + 60;
                    const y = (d.source.x < d.target.x)
                        ? Math.max(d.source.x, d.target.x) - 20
                        : Math.min(d.source.x, d.target.x) - 20;
                    return `translate(${x},${y})`;
                })
                .on('mouseover', function (event, d) { linkTooltip.show(d, this); })
                .on('mouseout', function (event, d) {
                    setTimeout(() => {
                        if (!d3.select('.link-tooltip:hover').empty()) return;
                        linkTooltip.hide(d, this);
                    }, 100);
                });

            linkTextGroups.append('foreignObject')
                .attr('width', 110)
                .attr('height', 40)
                .append('xhtml:div')
                .html(d => `
                    <div class="link-container">
                        <div class="link-value">${formatTons(d.target.data.value)} TON</div>
                        <div class="link-label">${d.target.data.label}</div>
                    </div>
                `);

            const gBBox = svg.node().getBBox();
            const newHeight = gBBox.height + margin.top + margin.bottom;
            const newWidth = gBBox.width + margin.left + margin.right;

            svgContainer
                .select('svg')
                .attr('height', newHeight)
                .attr('width', newWidth);

            // svg.attr('transform', `translate(50, ${newHeight / 2})`);
            svg.attr('transform', `translate(85, ${newHeight / 2}) scale(1)`);

            const diagramContainer = this.$refs.diagram;
            diagramContainer.style.width = `${newWidth}px`;
            diagramContainer.style.height = `${newHeight}px`;
        },

        updateScrollGradient() {
            const container = this.$refs.diagramContainer;
            const scrollLeft = container.scrollLeft;
            const scrollWidth = container.scrollWidth;
            const clientWidth = container.clientWidth;

            if (scrollLeft === 0) {
                container.classList.add('no-left-gradient');
            } else {
                container.classList.remove('no-left-gradient');
            }

            if (scrollLeft + clientWidth >= scrollWidth) {
                container.classList.add('no-right-gradient');
            } else {
                container.classList.remove('no-right-gradient');
            }
        },

        startDrag(event) {
            this.isDragging = true;
            // Начальные координаты
            this.startX = event.pageX - this.$refs.diagramContainer.offsetLeft;
            this.scrollLeft = this.$refs.diagramContainer.scrollLeft;

            // Прикрепляем обработчики события
            document.addEventListener('mousemove', this.onDrag);
            document.addEventListener('mouseup', this.stopDrag);
        },
        onDrag(event) {
            const x = event.pageX - this.$refs.diagramContainer.offsetLeft;
            const walk = (x - this.startX) * 1; // Скорость скроллинга
            this.$refs.diagramContainer.scrollLeft = this.scrollLeft - walk;
        },
        stopDrag() {
            this.isDragging = false;
            // Удаляем обработчики события после прекращения перетаскивания
            document.removeEventListener('mousemove', this.onDrag);
            document.removeEventListener('mouseup', this.stopDrag);
        },
    },
};
</script>

<style>
svg {
    background-color: transparent;
}

.link-value {
    font-size: 12px;
    color: var(--body-text-color);
}

.link-label {
    font-size: 12px;
    color: var(--body-muted-text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    white-space: nowrap;
}

.link-circle, .link-label, .link-value {
    cursor: default;
}

.link-container {
    display: flex;
    flex-direction: column;
    height: 40px;
    justify-content: space-between;
}

.d3-tip {
    padding: 12px;
    background: var(--card-background);
    color: var(--body-text-color);
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    border-radius: 6px;
    font-size: 13px;
}

.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
    color: var(--card-background);
}

.d3-tip.n:after {
    margin: -2px 0 0 0;
    top: 100%;
    left: 0;
}

.diagramOutline {
    position: relative;
    overflow: hidden;
}

.diagramGrabber {
    cursor: grab;
}

.diagramGrabber.is-dragging {
    cursor: grabbing;
}

.diagramGrabber::before,
.diagramGrabber::after {-webkit-transition: all .2s;-o-transition: all .2s;transition: all .2s;}

.diagramGrabber::before,
.diagramGrabber::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    height: calc(100% - 10px);
    pointer-events: none;
}

.diagramGrabber::before {
    left: 0;
    background: linear-gradient(to right, var(--body-background), rgba(255, 255, 255, 0));
}

.diagramGrabber::after {
    right: 0;
    background: linear-gradient(to left, var(--body-background), rgba(255, 255, 255, 0));
}

.diagramGrabber.no-left-gradient::before {
    opacity: 0;
}

.diagramGrabber.no-right-gradient::after {
    opacity: 1;
}

.diagramGrabber, .diagramWrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.diagramGrabber::-webkit-scrollbar, .diagramWrapper::-webkit-scrollbar {
  display: none;
}

/* .diagramGrabber::-webkit-scrollbar {
  height: 4px;
  background-color: var(--diagram-scroll-background);
}

.diagramGrabber::-webkit-scrollbar-thumb {
  background-color: var(--diagram-scroll);
  border-radius: 6px;
}

.diagramGrabber::-webkit-scrollbar-thumb:hover {
  background-color: var(--diagram-scroll-hover);
  border-radius: 6px;
}

.diagramGrabber::-webkit-scrollbar-track {
  background-color: var(--diagram-scroll-background);
}

.diagramGrabber::-webkit-scrollbar-corner {
  background: var(--diagram-scroll-background);
}

@supports (-ms-overflow-style: none) {
  .diagramGrabber {
    -ms-overflow-style: scrollbar;
  }
} */

.diagramWrapper {
    display: flex;
    overflow: hidden;
    margin: auto;
    justify-content: center;
    flex-shrink: 0;
}

/* .link-container, .node {
    position: relative;
} */

.link-container:after, .node:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.line-circle {
    width: 80px;
    height: 30px;
    font-size: 14px;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid var(--diagram-circle-color);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: var(--body-background);
    color: var(--blue-bright);
    font-weight: 400;
    cursor: pointer!important;
    z-index: 5;
}

.node-group, .link-text-group {
    cursor: pointer;
}

/* .line-circle-wrapper {
    position: relative;
} */

.highlight {
    background: var(--diagram-line-color-hover);
}

.node {
    overflow: visible;
}

.line-select .line-circle {
    background: var(--blue-bright);
    border-color: var(--blue-bright);
    color: #fff;
}

/* .line-select:before {
    content: "";
    background: var(--blue-bright);
    border-radius: 9px;
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    pointer-events: none;
} */
</style>
