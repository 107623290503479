<template>
    <div class="widget">
        <div class="widget-header">
            <div class="widget-title">
                {{ $t('indexpage.apps_and_services') }}
            </div>
            <div class="widget-link widget-apps-link">
                <ui-link v-bind:to="{ name: 'apps' }">
                    {{ $t('indexpage.all_apps') }}
                </ui-link>
            </div>
        </div>
        <div class="widget-content">
            <div v-if="appsData" class="widget-apps">
                <trending-app-item
                    v-for="(app, index) in appsData"
                    v-bind:key="index"
                    v-bind:name="app.title"
                    v-bind:description_en="app.descriptions.get('en')"
                    v-bind:description_ru="app.descriptions.get('ru')"
                    v-bind:image="`https://i.tonscan.org/apps${app.icon}`"
                    v-bind:link="`https://tonscan.org/apps/${app.categorySlug}/${app.slug}`"
                />
            </div>
            <div v-else class="widget-apps">
                <trending-app-item-skeleton
                    v-for="i in 3"
                    v-bind:key="i"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { searchApps } from '~/components/apps/index.js';
import TrendingAppItem from './TrendingAppItem.vue';
import TrendingAppItemSkeleton from './TrendingAppItemSkeleton.vue';
import UiLink from '../../UiLink.vue';

export default {
    data() {
        return {
            appsData: null,
        };
    },

    mounted() {
        this.loadTopApps();
    },

    methods: {
        async loadTopApps() {
            try {
                const paramsObj = {
                    per_page: 3,
                    query_by: 'name,tags,category_slug',
                    page: 1,
                    filter: 'weight:>0',
                    q: '*',
                    sort_by: 'weight:DESC',
                };

                const apps = await searchApps(paramsObj);
                this.appsData = apps.hits;
            } catch (error) {
                console.log('Error loading top apps:', error);
            }
        },
    },

    components: {
        TrendingAppItem,
        UiLink,
        TrendingAppItemSkeleton,
    },
};
</script>

<style lang="scss">

.widget-apps {
    padding: 5px 0 10px 0;
}

.widget-apps-item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin: 0 10px;
    border-radius: 10px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.widget-apps a:hover {
    background: var(--body-light-muted-color);
}

.widget-apps-item:last-child {
    margin-bottom: 0;
}

.widget-apps-item:hover {
    text-decoration: none;
}

.widget-apps-img {
    width: 50px;
    flex: 0 0 50px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.widget-apps-name {
    color: var(--body-text-color);
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 500;
}

.widget-apps-description {
    color: var(--body-muted-text-color);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.widget-apps-item img {
    width: 100%;
    vertical-align: top;
}
</style>
