<template>
    <ui-link v-bind:to="{ name: 'stats' }" class="router-link__no-style" style="width: 100%;">
        <div class="indexpage-mobile-numbers" v-if="price && marketcap">
            <div class="indexpage-mobile-numbers__block indexpage-mobile-numbers__block--left">
                <IconTon class="indexpage-mobile-numbers__ton-logo" />${{ price.toFixed(2) }}
            </div>
            <div class="indexpage-mobile-numbers__block indexpage-mobile-numbers__block--right">
                {{ $t('indexpage.market_overview') }}: ${{ marketcap }}
            </div>
        </div>
        <div class="indexpage-mobile-numbers" v-else>
            <div class="indexpage-mobile-numbers__block indexpage-mobile-numbers__block--left">
                <span class="skeleton" style="width: 60px;">test</span>
            </div>
            <div class="indexpage-mobile-numbers__block indexpage-mobile-numbers__block--right">
                <div class="skeleton" style="width: 50%; margin-left: 50%;">test</div>
            </div>
        </div>
    </ui-link>
</template>

<script>
import { getMarketDataHistory } from '~/api/coingecko.js';
import { prefixNumber } from '~/lib/Chart.js/helpers.js';
import IconTon from '@img/icons/tonscan/ton-24.svg?inline';

export default {
    data() {
        return {
            marketcap: null,
            price: null,
        };
    },

    mounted() {
        this.getMarketPrice();
    },

    methods: {
        async getMarketPrice() {
            try {
                const {
                    prices,
                    market_caps,
                } = await getMarketDataHistory({
                    currency: 'USD',
                    days: 14,
                    interval: 'daily',
                });

                // Set number values
                this.marketcap = prefixNumber(market_caps.at(-1)[1], this.$i18n.locale);
                this.price = prices.at(-1)[1];
            } catch (error) {
                console.log(error);
            }
        },
    },

    components: { IconTon },
};
</script>

<style lang="scss">
.indexpage-mobile-numbers {
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    padding: 0 16px;

    &__ton-logo {
        width: 20px;
        height: 20px;
        margin-right: 2px;
        fill: var(--badge-blue-color);
    }

    &__block {
        &--left {
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &--right {
            color: var(--body-muted-text-color);
            text-align: right;
            flex-grow: 1;
        }
    }
}
</style>
