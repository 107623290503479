<template>
    <div class="widget">
        <div class="widget-stakee-header">
            <div class="widget-stakee-header__content">
                <div class="widget-title">
                    {{ $t('indexpage.stakee.header_top') }}
                </div>
                <div class="widget-subtitle">
                    {{ $t('indexpage.stakee.header_bottom') }}
                </div>
            </div>
            <div class="widget-stakee-header__logo">
                <stakee-logo />
            </div>
        </div>
        <div class="widget-stakee-description" v-html="$t('indexpage.stakee.description')" />
        <!-- <div class="widget-stakee-description muted">
            {{ $t('indexpage.stakee.description_muted') }}
        </div> -->
        <div class="widget-stakee-btn__container">
            <a href="https://t.me/StakeeBot/start" class="widget-stakee-btn" target="_blank">
                {{ $t('indexpage.stakee.stake_ton') }}
            </a>
        </div>
    </div>
</template>

<script>
import StakeeLogo from '@img/icons/tonscan/stakee.svg?inline';

export default {
    components: {
        StakeeLogo,
    },
};
</script>

<style lang="scss">
.widget-stakee-header {
    padding: 24px 20px 16px 20px;
    display: flex;
    align-items: center;
    gap: 14px;
    color: var(--widget-stakee-header-color);
    justify-content: space-between;

    &__logo {
        opacity: .2;
    }

    &__bottom-text {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
    }

    svg {
        width: auto;
        height: 41px;
    }
}

.widget-subtitle {
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
}

.widget-stakee-description {
    padding: 0 20px 7px 20px;
    font-size: 14px;
    line-height: 24px;
}

.widget-stakee-btn {
    display: block;
    text-align: center;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: #FFF;
    background-color: var(--big-blue-button-background);
    padding: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        background-color: var(--big-blue-button-hover-background);
        text-decoration: none;
    }

    &__container {
        padding: 8px 20px 18px 20px;
    }
}
</style>
