<template>
    <div class="card">
        <div class="card-title">
            <icon-tx-type-ordinary class="card-title__icon" /> {{ $t('tx.title') }}
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.account') }}</span>
            </div>
            <div class="card-row__value">
                <ui-copy-button v-bind:copy="transactionData.account"
                    v-bind:successMessage="$t('tx.hash_copy_success')">
                    <ui-address v-bind:address="transactionData.account" class="tx-status-preview__address" />
                </ui-copy-button>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.status') }}</span>
            </div>
            <div class="card-row__value">
                <span class="tx-status" v-bind:class="{
                    'tx-status--success': transactionData.end_status === 'active',
                    'tx-status--failed': !transactionData.end_status !== 'active',
                }">
                    <template v-if="transactionData.end_status === 'active'">
                        <icon-success class="tx-status__icon" />
                        {{ $t('tx.status_success') }}
                    </template>
                    <template v-else>
                        <icon-failed class="tx-status__icon" />
                        {{ $t('tx.status_failed') }}
                    </template>
                </span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.time') }}</span>
            </div>
            <div class="card-row__value">
                {{ formattedDate }}
                <span class="card-timeago">&mdash;</span>
                <ui-timeago class="card-timeago" v-bind:timestamp="transactionData.now * 1000" />
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>LT</span>
            </div>
            <div class="card-row__value">
                <ui-copy-button v-bind:copy="transactionData.lt"
                    v-bind:successMessage="$t('tx.lt_copy_success')">
                    {{ transactionData.lt }}
                </ui-copy-button>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>{{ $t('tx.hash') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row">
                    <div class="card-row__name">base64</div>
                    <div class="card-row__value">
                        <ui-copy-button v-bind:copy="transactionData.hash"
                            v-bind:successMessage="$t('tx.hash_copy_success')">
                            {{ transactionData.hash }}
                        </ui-copy-button>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name">hex</div>
                    <div class="card-row__value">
                        <ui-copy-button style="text-transform: lowercase;" v-bind:copy="formattedHex"
                            v-bind:successMessage="$t('tx.hash_copy_success')">
                            {{ formattedHex }}
                        </ui-copy-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-row">
            <div class="card-row__name">
                <span>{{ $t('tx.fee') }}</span>
            </div>
            <div class="card-row__value card-row__value--flex-row">
                <div class="card-row">
                    <span>{{ $fee(totalFeesWithFwd) }} TON</span>
                </div>

                <!-- <div class="card-row">
                    <div class="card-row__name">storage</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.total_fees) }} TON</span>
                    </div>
                </div>

                <div class="card-row">
                    <div class="card-row__name">other</div>
                    <div class="card-row__value">
                        <span>{{ $fee(transactionData.total_fees) }} TON</span>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="card-row" >
            <div class="card-row__name">
                <span>seqno</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.mc_block_seqno }}</span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>now</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.now }}</span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>orig_status</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.orig_status }}</span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>prev_trans</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.prev_trans_hash }}</span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>prev_trans_lt</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.prev_trans_lt }}</span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>total_fees</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.total_fees }}</span>
            </div>
        </div>
        <div class="card-row" >
            <div class="card-row__name">
                <span>trace_id</span>
            </div>
            <div class="card-row__value">
                <span>{{ transactionData.trace_id }}</span>
            </div>
        </div> -->

        <div class="card-row" v-bind:class="{ 'no-border': descriptionOpen }" style="cursor: pointer;" v-on:click="toggleDescription">
            <div class="card-row__name" v-text="$t('tx.description')" />
            <div class="card-row__value">
                <span>{{ $t('tx.json') }}</span>
            </div>
            <div class="card-row__chevron">
                <icon-expand v-bind:class="{ 'card-row__chevron-expand': descriptionOpen }" />
            </div>
        </div>
        <div class="tx-page-messages tx-page-json" v-if="descriptionOpen">
            <SourceJson v-bind:transactionDescription="transactionData.description" />
        </div>

        <div class="card-row" style="border-bottom: 0; cursor: pointer;" v-on:click="toggleMsg">
            <div class="card-row__name" v-text="$t('tx.msgs')" />
            <div class="card-row__value">
                <span v-if="!transactionData.in_msg && !transactionData.out_msgs.length" v-text="$t('tx.msgs_empty')" />
                <span v-else v-text="$t('tx.msgs_count', [1, transactionData.out_msgs.length])" />
            </div>
            <div class="card-row__chevron">
                <icon-expand v-bind:class="{ 'card-row__chevron-expand': msgOpen }" />
            </div>
        </div>
        <div class="tx-page-messages" v-show="msgOpen">
            <div v-if="transactionData.in_msg" class="tx-page-msg">
                <div class="card-row__name">
                    <span class="tx-table__badge tx-table__badge--in">IN</span>
                </div>
                <tx-msg class="tx-page-msg-details"
                    v-bind:source="transactionData.in_msg.source"
                    v-bind:destination="transactionData.in_msg.destination"
                    v-bind:value="transactionData.in_msg.value"
                    v-bind:fwdFee="transactionData.in_msg.fwd_fee"
                    v-bind:ihrFee="transactionData.in_msg.ihr_fee"
                    v-bind:createdLt="transactionData.in_msg.created_lt"
                    v-bind:hash="transactionData.in_msg.hash"
                    v-bind:comment="transactionData.in_msg.message_content?.decoded?.comment"
                    v-bind:op="transactionData.in_msg.opcode" />
            </div>

            <div class="tx-page-msg" v-for="msg in transactionData.out_msgs" v-bind:key="msg.hash">
                <div class="card-row__name">
                    <span v-if="!msg.destination" class="tx-table__badge tx-table__badge--service"
                        v-text="$t('address.tx_table.log')" />

                    <span v-else class="tx-table__badge tx-table__badge--out" v-text="$t('address.tx_table.output')" />
                </div>

                <tx-msg class="tx-page-msg-details"
                    v-bind:source="msg?.source || null"
                    v-bind:destination="msg?.destination || null"
                    v-bind:value="msg.value"
                    v-bind:fwdFee="msg.fwd_fee"
                    v-bind:ihrFee="msg.ihr_fee"
                    v-bind:createdLt="msg.created_lt"
                    v-bind:hash="msg.hash"
                    v-bind:comment="msg.message_content?.decoded?.comment"
                    v-bind:op="msg.opcode" />
            </div>
        </div>
    </div>
</template>

<script>
import IconTxTypeOrdinary from '@primer/octicons/build/svg/git-commit-24.svg?inline';
import IconExpand from '@img/icons/tonscan/chevron-bottom-14.svg?vue';
import IconSuccess from '@primer/octicons/build/svg/check-circle-16.svg?inline';
import IconFailed from '@primer/octicons/build/svg/x-circle-16.svg?inline';
import { base64ToHex } from '~/utils.js';
import TxMsg from '../TxMsg.vue';
import SourceJson from './Json/SourceJson.vue';
import { formatTimestamp } from '../../../helpers';

export default {
    props: {
        transactionData: Object,
    },

    data() {
        return {
            msgOpen: true,
            descriptionOpen: false,
            hashHex: null,
        };
    },

    methods: {
        toggleMsg() {
            this.msgOpen = !this.msgOpen;
        },
        toggleDescription() {
            this.descriptionOpen = !this.descriptionOpen;
        },
    },

    computed: {
        formattedHex() {
            console.log(this.transactionData.account);
            return base64ToHex(this.transactionData.hash);
        },

        formattedDate() {
            console.log(this.transactionData);
            return formatTimestamp(this.transactionData.now * 1000);
        },

        totalFeesWithFwd() {
            const decimals = 9;
            const divisor = 10 ** decimals;

            const allFwdFees = this.transactionData.out_msgs.map(msg => Number(msg.fwd_fee) / divisor || 0);

            const sumFwdFee = allFwdFees.reduce((sum, fee) => sum + fee, 0);
            const totalFees = this.transactionData.total_fees / divisor;
            const total = totalFees + sumFwdFee;
            const totalAtomicUnits = Math.round(total * divisor);

            return totalAtomicUnits;
        },

        description() {
            return this.transactionData.description || {};
        },
        formattedDescription() {
            return JSON.stringify(this.description, null, 4);
        },
    },

    components: {
        IconTxTypeOrdinary, TxMsg, IconExpand, IconSuccess, IconFailed, SourceJson,
    },
};
</script>

<style lang="scss">
.no-border {
    border-bottom: 0!important;
}
</style>
