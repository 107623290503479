<template>
    <div class="app__info">
        <app-info-item v-bind:title="$t('apps.app.category')" v-bind:loading="loading">
            <ui-link v-bind:to="{ name: 'apps-category', params: { category: categorySlugTransformed } }">
                {{ categoryTitle }}
            </ui-link>
        </app-info-item>

        <app-info-item v-bind:title="$t('apps.app.website')" v-bind:loading="loading">
            <a v-bind:href="link" target="_blank">{{ website }}</a>
        </app-info-item>

        <app-info-item v-if="address" v-bind:loading="loading" v-bind:title="$t('apps.app.blockchain_address')">
            <ui-copy-button
                class="card-main-address"
                v-bind:successMessage="$t('address.info.copy_success')"
                v-bind:copy="address"
            >
                <ui-address hide-name v-bind:address="address" />
            </ui-copy-button>
        </app-info-item>
    </div>
</template>

<script>
import AppInfoItem from '~/components/apps/App/AppInfoItem.vue';

export default {
    name: 'AppInfo',

    props: {
        address: String,
        categorySlug: String,
        link: String,
        loading: Boolean,
    },

    inject: ['getCategories', 'getCategoryBySlug'],

    data() {
        return {
            website: null,
        };
    },

    computed: {
        categoryTitle() {
            const category = this.getCategoryBySlug(this.categorySlug, true);
            return this.$t(`apps.categories.${category}`);
        },
    },

    watch: {
        loading(newVal) {
            if (!newVal) {
                this.setInfo();
            }
        },
    },

    methods: {
        setInfo() {
            this.setLink();
        },

        setLink() {
            let link = this.link.trim();

            if (!/^https?:\/\//i.test(link)) {
                link = `https://${link}`;
            }

            try {
                const linkURL = new URL(link);

                // Telegram links must contain only @name
                if (linkURL.hostname === 't.me') {
                    this.website = linkURL.pathname.replace('/', '@').split('/')[0];
                } else if (linkURL.hostname === 'github.com') {
                    this.website = `${linkURL.hostname}${linkURL.pathname}`;
                } else {
                    this.website = linkURL.hostname;
                }
            } catch (e) {
                console.log('Invalid URL:', link);
                this.website = link;
            }
        },
    },

    components: {
        AppInfoItem,
    },
};
</script>

<style lang="scss" scoped>
.address-link:hover {
  text-decoration: underline;
}
</style>
