<template>
    <t-row v-bind:gap="20">
        <t-col
            v-for="(app, index) in apps"
            v-bind:key="index"
            v-bind:cols="6"
            v-bind:tablet="6"
            v-bind:mobile="12"
        >
            <app-card
                v-if="!app"
                is-loading
                class="page-app-similar-app"
            />
            <ui-link
                v-else
                class="router-link__no-style"
                v-bind:to="{
                    name: 'app',
                    params: { app: app.slug },
                }"
            >
                <app-card
                    class="page-app-similar-app"
                    v-bind:title="app.title"
                    v-bind:descriptions="app.descriptions"
                    v-bind:icon="app.icon"
                    v-bind:isAds="app.isAds"
                    v-bind:isTrending="app.isTrending"
                    v-bind:isNew="app.isNew"
                    v-bind:isVerified="app.isVerified"
                />
            </ui-link>
        </t-col>
    </t-row>
</template>

<script>
import { searchApps } from '~/components/apps/index.js';
import AppCard from '~/components/apps/List/AppCard.vue';

const makeSkeleton = count => Array(count).fill(null);

export default {
    props: {
        categorySlug: String,
        appSlug: String,
        count: {
            type: Number,
            default: 2,
        },
    },

    data() {
        return {
            apps: makeSkeleton(this.count),
            loading: true,
        };
    },

    created() {
        this.loadSimilarApps();
    },

    watch: {
        categorySlug: 'loadSimilarApps',
        appSlug: 'loadSimilarApps',
    },

    methods: {
        async loadSimilarApps() {
            if (!this.categorySlug || !this.appSlug) return;

            this.apps = makeSkeleton(this.count);
            this.loading = true;

            try {
                const { hits: similarApps } = await searchApps({
                    q: '*',
                    per_page: 10,
                    query_by: 'category_slug',
                    sort_by: 'weight:DESC',
                    filter_by: `category_slug:${this.categorySlug} && slug:!=${this.appSlug} && weight:>0`,
                });

                const apps = [similarApps.shift()];

                while (apps.length < this.count) {
                    const randomIndex = Math.floor(similarApps.length * Math.random());
                    const [randomApp] = similarApps.splice(randomIndex, 1);
                    if (randomApp) {
                        apps.push(randomApp);
                    } else {
                        break;
                    }
                }

                this.apps = apps;
            } catch (error) {
                console.log('Error loading similar apps:', error);
            } finally {
                this.loading = false;
            }
        },
    },

    components: {
        AppCard,
    },
};
</script>

<style lang="scss">
@media screen and (max-width: 599px) {
    .page-app-block-similar .t-row {
        gap: 20px;
    }
}

@media screen and (max-width: 480px) {
    .page-app-block-similar .t-row {
        gap: 0px;
    }

    .page-app-similar-app {
        margin: 0 -16px;
        border-radius: 0!important;
        border: 0px solid var(--card-border-color);
        border-width: 1px 0!important;
        margin-bottom: -1px; // collapses border between items
    }

}
</style>
