<template>
    <main class="tx-code-json">
        <ui-copy-button class="source-viewer-code__copy tx-code-json-copy" v-bind:copy="formattedDescription"/>
        <div class="tx-code tx-page-msg">{{ formattedDescription }}</div>
    </main>
</template>

<script>

export default {
    props: {
        transactionDescription: Object,
    },

    computed: {
        description() {
            return this.transactionDescription || {};
        },
        formattedDescription() {
            return JSON.stringify(this.description, null, 4);
        },
    },
};
</script>

<style lang="scss">
.tx-code-json {
    position: relative;
}

.tx-code {
    padding: 10px;
    display: block;
    white-space: pre-wrap;
    word-break: break-all;
}

.tx-code-json-copy {
    background: var(--tx-messages-message-background);
}
</style>
