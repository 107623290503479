<template>
    <div class="widget">
        <div class="widget-content widget-channel-container">
            <template v-if="newsData.length > 0">
                <news-feed-item
                    v-for="(news, index) in newsData"
                    v-bind:key="`news-id-${index}`"
                    v-bind:id="news.id"
                    v-bind:channelId="news.channelId"
                    v-bind:channelUsername="news.channelUsername"
                    v-bind:channelName="news.channelName"
                    v-bind:text="news.text"
                    v-bind:date="news.date"
                />
            </template>
            <template v-else>
                <news-feed-skeleton v-for="i in 4" v-bind:key="`news-skeleton-${i}`" />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getRuNews, getEnNews } from '~/api/news.js';
import NewsFeedItem from './NewsFeedItem.vue';
import NewsFeedSkeleton from './NewsFeedSkeleton.vue';

export default {
    data() {
        return {
            newsData: [],
            isLoading: true,
            // hasMore: true,
        };
    },

    computed: {
        shouldHandleScroll() {
            return this.isLoading && this.hasMore && this.newsData.length > 0;
        },

        showPreloader() {
            return this.newsData.length > 0 && this.hasMore;
        },

        ...mapState({
            newsLanguage: 'appLocale',
        }),
    },

    watch: {
        newsLanguage: {
            immediate: true,
            handler() {
                this.resetNewsData();
                this.getNewsInfo();
            },
        },
    },

    mounted() {
        this.getNewsInfo();
    },

    methods: {
        resetNewsData() {
            this.hasMore = true;
            this.newsData = [];
        },

        async getNewsInfo() {
            try {
                const lang = this.$i18n.locale;
                const limit = 24;
                const channels = {
                    ru: [1433990798, 2020833003, 1444152677],
                    en: [1191264954, 1744725639, 1379327992, 1497424960],
                };

                const getNews = lang === 'ru' ? getRuNews : getEnNews;
                const { channels: getChannelsData, posts: getNewsData } = await getNews(channels[lang], limit);

                const parsedData = getNewsData.map(item => ({
                    channelId: item.channel_id,
                    channelUsername: getChannelsData[item.channel_id].username,
                    channelName: getChannelsData[item.channel_id].title,
                    id: item.post_id,
                    date: item.date,
                    text: item.text,
                }));

                this.newsData = parsedData;
                if (parsedData.length < 8) this.hasMore = false;
            } catch (error) {
                console.log(error);
            }
        },
    },

    components: {
        NewsFeedItem,
        NewsFeedSkeleton,
    },
};
</script>

<style lang="scss">
.widget-channel-container {
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .widget {
        width: 100%;
    }
}
</style>
